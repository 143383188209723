import React, { useContext, useState } from 'react'
import { EffectFade, FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react'

import LaravelLogo from '../../assets/images/LaravelLogo';
import ASPNetLogo from '../../assets/images/ASPNetLogo';
import { East, GitHub } from '@mui/icons-material';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import './LearnSwiper.scss';
import { Button } from '@mui/material';
import { BasicButton } from '../button';
import { ColorsContext } from '../../context/Colors';
import { Link } from 'react-router-dom';

const data = [
    {
        id: 'laravel',
        name: "Laravel",
        icon: <LaravelLogo />
    },
    {
        id: 'asp-net',
        name: "ASP.NET",
        icon: <ASPNetLogo />
    },
    {
        id: 'git-hub',
        name: "GitHub",
        icon: <GitHub />
    }
]

export default function LearnSwiper() {
    const { handleHeaderColor } = useContext(ColorsContext);
    
    const [thumbsSwiper, setThumbsSwiper] = useState(null);


    const handleActive = (value) => {
        let color;

        console.log(value)

        switch (value) {
            case 'laravel':
                color = '#ef3e30';
                break;
            case 'asp-net':
                color = '#9A89D2';
                break;
            case 'git-hub':
                color = '#000000';
                break;
        }

        handleHeaderColor(color);
    }

    return (
        <div className='learn-swiper'>
            <Swiper
                style={{
                '--swiper-navigation-color': '#000',
                '--swiper-pagination-color': '#000',
                }}
                effect='fade'
                loop={true}
                watchSlidesProgress={true}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[EffectFade, FreeMode, Navigation, Thumbs]}
                className="swiper-slide"
            >
                {data.map((lang) => (
                    <SwiperSlide className='slide' key={lang.id}>
                        <div>
                            <h3>Aprende {lang.name}</h3>
                            <BasicButton 
                                icon={lang.icon}
                                component={Link}
                                to={`/aprende/${lang.id}`}
                                title='Aprender'
                                rightIcon={<East />}
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={16}
                slidesPerView={3}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="swiper-thumbs"
            >
                {data.map((lang) => (
                    <SwiperSlide onClick={() => handleActive(lang.id)} className='thumb' key={lang.id}>
                        {lang.icon}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
