import { createContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getLuminance, hexToRgb } from "../utils";

export const ColorsContext = createContext();

export const ColorsProvider = ({ children }) => {
    const params = useParams();
    const location = useLocation();

    const [ headerColors, setHeaderColors ] = useState({
        background: '255, 255, 255',
        text: '0, 0, 0',
        complementary: '239, 62, 48',
        complementaryText: '0, 0, 0'
    })

    const [ currentColor, setCurrentColor ] = useState('#EF3E30');
    const [ updating, setUpdating ] = useState(false);

    const resetToDefault = () => {
        setHeaderColors({
            background: '255, 255, 255',
            text: '0, 0, 0',
            complementary: '239, 62, 48',
            complementaryText: '0, 0, 0'
        })
    }

    const handleHeaderColor = (color) => {
        const { r, g, b } = hexToRgb(color);

        const complementaryColor = {
            r: 255 - (r / 10),
            g: 255 - (g / 10),
            b: 255 - (b / 10),
        }

        const getTextColor = (r, g, b) => {
            return getLuminance(r, g, b) > 0.5 ? '0, 0, 0' : '255, 255, 255';
        };

        setHeaderColors({
            background: `${r}, ${g}, ${b}`,
            text: getTextColor(r, g, b),
            complementary: `${complementaryColor.r}, ${complementaryColor.g}, ${complementaryColor.b}`,
            complementaryText: getTextColor(complementaryColor.r, complementaryColor.g, complementaryColor.b)
        })
    }

    const changeColor = (color) => {
        if (currentColor === color) {
            return;
        }

        setUpdating(true);
        setTimeout(() => {
            setCurrentColor(color);
            setTimeout(() => {
                setUpdating(false);
            }, 600)
        }, 500)
    }

    useEffect(() => {
        if (params.language === 'asp-net') {
            changeColor('#ac99ea');
        } else if (params.language === 'laravel') {
            changeColor('#EF3E30');
        }
    }, [location.pathname])

    useEffect(() => {
        document.documentElement.style.setProperty('--branch-color', currentColor);
    }, [currentColor])

    return (
        <ColorsContext.Provider
            value={{
                currentColor,
                setCurrentColor,
                updating,
                setUpdating,
                headerColors,
                setHeaderColors,
                resetToDefault,
                handleHeaderColor
            }}
        >
            {children}
        </ColorsContext.Provider>
    )
}