import React from 'react';
import './styles/index.scss';
import './styles/page.scss';
import { SnackbarProvider } from 'notistack';
import SlideTransition from './components/transition/SlideTransition';

export default function App({ children }) {
  return (
    <SnackbarProvider 
      maxSnack={3} 
      TransitionComponent={SlideTransition}
      autoHideDuration={3000}
    >
      {children}
    </SnackbarProvider>
  )
}
