const hexToRgb = (hex) => {
    // Eliminar el símbolo # si está presente
    const sanitizedHex = hex.replace(/^#/, '');
    const bigint = parseInt(sanitizedHex, 16);
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
    };
};

export default hexToRgb;