import React, { useEffect } from 'react'
import { Header } from '../components/header'
import { Outlet, useLocation } from 'react-router-dom'
import { ModalManagerProvider } from '../components/modal';
import { Footer } from '../components/footer';
import { ColorsProvider } from '../context/Colors';

export default function Layout() {
    const location = useLocation();

    useEffect(() => {    
        window.scrollTo(0,0);
    }, [location.pathname])

    return (
        <ColorsProvider>
            <ModalManagerProvider>
                <Header />
                <div style={{ minHeight: '80vh'}}>
                    <Outlet />
                </div>
                <Footer />
            </ModalManagerProvider>
        </ColorsProvider>
    )
}
