import React from 'react'

export default function Name({ blank }) {
  return (
    <span className={`font-name ${blank ? 'blank' : ''}`} >
        andrescortes
        <span className='dot'>.dev</span>
    </span>
  )
}
