import React, { useContext, useEffect, useState } from 'react';
import './Header.scss';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { BasicButton } from '../button';
import { HandshakeOutlined, Menu, OpenInNew } from '@mui/icons-material';
import { LinearProgress, useMediaQuery } from '@mui/material';
import { Modal, useModalManager } from '../modal';
import HeaderMenuModal from '../modal/dist/HeaderMenuModal';
import { ColorsContext } from '../../context/Colors';
import navigation from './navigation.json';
import Name from '../Name';

const NavHeaderLink = ({ to, title }) => {
    const [ isActive, setIsActive ] = useState(false);
    const [ classNamer, setClassNamer ] = useState('');
    
    useEffect(() => {
        if (isActive) {
            setClassNamer('active');
            setTimeout(() => {
                setClassNamer('active removeLineAnimation');
                setTimeout(() => {
                    setClassNamer('active removeLine');
                    setTimeout(() => {
                        setClassNamer('active removeLineTransition')
                    }, 100);
                }, 100)
            }, 500)
        } else {
            setClassNamer('');
        }
    }, [isActive])

    return (
        <NavLink
            to={to}
            className={({ isActive }) => {
                setIsActive(isActive);
                return `nav-link-click ${classNamer}`;
              }}
        >
            {title}
        </NavLink>
    )
}

export default function Header() {
    const location = useLocation();
    
    const { updating, headerColors } = useContext(ColorsContext);
    const { openModal, closeModal } = useModalManager();

    const [ scrolled, setScrolled ] = useState(false);

    const match = useMediaQuery('(max-width: 960px)');

    const handleSidebar = () => {
        openModal('sidebar', (
            <Modal
                close={() => closeModal('sidebar')}
            >
                <HeaderMenuModal />
            </Modal>
        ))
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 16) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header 
            style={{ 
                '--background-color': headerColors.background,
                '--text-color': headerColors.text,
                '--complementary-color': headerColors.complementary,
                '--complementary-text': headerColors.complementaryText
            }} 
            className='main-header'
        >
            <div className={`header-upper ${location.pathname.split('/')[1]} ${scrolled ? 'scrolled' : ''}`}>
                <div className="container">
                    <div className="header-inner flex align-center">
                        <div className="logo-outer">
                            <div className={`logo ${headerColors.background !== '255, 255, 255' ? 'colored' : ''}`}>
                                <Link to="/" className='font-name'>
                                    <Name />
                                </Link>
                            </div>
                        </div>
                        <div className="nav-outer">
                            <nav className="main-menu">
                                <div className="navbar-header">

                                </div>
                                <div className="navbar-collapse">
                                    <ul className='navigation'>
                                        {navigation.map((nav, i) => (
                                            <li key={i}>
                                                <NavHeaderLink to={nav.to} title={nav.title} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="menu-btns gap-10 flex">
                            <BasicButton 
                                variant='outlined'
                                className='hider'
                                title='Documentación'
                                component={Link}
                                target='_blank'
                                rightIcon={<OpenInNew />}
                                to="https://docs.andrescortes.dev"
                            />
                            {match &&
                                <BasicButton 
                                    onClick={handleSidebar}
                                    icon={<Menu />}
                                    className='menu'
                                />
                            }
                        </div>
                    </div>
                </div>
                {updating && <LinearProgress className='header-progress' />}
            </div>
        </header>
    )
}
