import React, { useContext, useEffect, useState } from 'react'
import { LetsTalk } from '../../components/sections'
import { Section } from '../../components'
import { Input } from '../../components/input'
import { BasicButton } from '../../components/button'
import { Check, Email, Place } from '@mui/icons-material'
import { useMediaQuery } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { ColorsContext } from '../../context/Colors'

export default function Contact() {
    const match = useMediaQuery('(max-width: 570px)');
    const { resetToDefault } = useContext(ColorsContext);

    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');

    const [ message, setMessage ] = useState('');

    const [ emailError, setEmailError ] = useState(false);

    const [ disabled, setDisabled ] = useState(true);

    const [ sending, setSending ] = useState(false);
    const [ send, setSend ] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSending(true);
    
        const webhookURL = process.env.REACT_APP_DISCORD_WEBHOOK;
    
        if (!name || !message || !email) {
            console.error("Todos los campos son obligatorios");
            setSending(false);
            return;
        }
    
        const data = {
            embeds: [{
                title: name,
                description: message,
                fields: [
                    {
                        name: "Nombre",
                        value: name,
                        inline: false
                    },
                    {
                        name: "Correo",
                        value: email,
                        inline: false
                    }
                ],
                color: 0xEF3E30
            }]
        };
    
        try {
            const response = await fetch(webhookURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                const errorData = await response.json(); 
                enqueueSnackbar('Algo salió mal.', { variant: 'error'});
                console.error("Error en la solicitud:", errorData);
            } else {
                setSend(true);
                enqueueSnackbar('¡Recibimos tu mensaje!', { variant: 'default'})
                console.log("Mensaje enviado correctamente:", response);
            }
        } catch (error) {
            console.error("Error al enviar la solicitud:", error);
        } finally {
            setSending(false);
        }
    };
    
    useEffect(() => {
        const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    
        if (email.length < 1) {
            setEmailError(false);
            return;
        }
    
        if (email.match(validRegex)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    }, [email]);

    useEffect(() => {
        setSend(false);

        if (!name || !message || !email) {
            setDisabled(true);
            return;
        }

        if (message.length < 10) {
            setDisabled(true);
            return;
        }

        if (emailError) {
            setDisabled(true);
            return;
        }

        setDisabled(false)
    }, [name, message, email]);

    useEffect(() => {
        resetToDefault();
    }, [])
      
    return (
        <>
            <title>Contacto - andrescortes.dev</title>
            <div className="section first">

            </div>
            <Section id="contact" className="contact-area">
                <div className="container">
                    <div className="home-section">
                        <div className="section-title align-center">
                            <h2>Póngase en contacto conmigo</h2>
                        </div>
                        <div className="section-body margin-top-15">
                            <div className='data'>
                                <ul>
                                    <li>
                                        <Place />
                                        <h3>Ubicación:</h3>
                                        <p>Colombia</p>
                                    </li>
                                    <li>
                                        <Email />
                                        <h3>Envíenos un correo:</h3>
                                        <a href="mailto:contacto@andrescortes.dev ">contacto@andrescortes.dev </a>
                                    </li>
                                </ul>
                            </div>
                            <form onSubmit={handleSubmit} className="form flex flow-column gap-10">
                                <div className={`flex flow-${!match ? 'row' : 'column'} gap-10`}>
                                    <Input 
                                        label='Nombre'
                                        required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <Input 
                                        label='Correo electrónico'
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        helperText={emailError && 'El correo no es válido.'}
                                        error={emailError}
                                    />
                                </div>
                                <div className='flex'>
                                    <Input 
                                        type='text-area'
                                        label='Tu mensaje'
                                        helperText='Mínimo 10 carácteres.'
                                        required
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <BasicButton 
                                        loading={sending}
                                        title={send ? '' : 'Enviarme mensaje'}
                                        rightIcon={send ? <Check /> : <Email />}
                                        type='submit'
                                        disabled={disabled}
                                        shadow='laravel'
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    )
}
