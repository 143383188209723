import React, { useEffect, useState } from 'react';
// @ts-ignore
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface LazyLoadImageProps {
    className?: string;
    style?: object;
    figureStyle?: object;
    src: string;
    srcSet?: string;
    width?: number;
    height?: number;
    isRadius?: boolean;
    borderRadius?: number;
    aspectRatio?: number;
    blur?: number;
    alt?: string;
    maxWidth?: string | number;
    maxHeight?: string | number;
    objectFit?: 'cover' | 'contain';
    isSvg?: boolean;
}

const LazyImage: React.FC<LazyLoadImageProps> = ({ 
    className, 
    style, 
    figureStyle,
    src, 
    srcSet,
    width, 
    height, 
    isRadius, 
    borderRadius, 
    aspectRatio, 
    blur, 
    alt,
    maxWidth,
    objectFit,
    isSvg,
    maxHeight,
    ...props
}) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (isSvg) {
            setLoaded(true)
        }
    }, [])

    return (
        <div 
            style={{ 
                width: width ? width : '100%', 
                height: height ? height : '100%', 
                borderRadius: borderRadius ? borderRadius : isRadius ? 1000 : 0,
                aspectRatio: aspectRatio && aspectRatio, 
                maxWidth: maxWidth && maxWidth,
                maxHeight: maxHeight && maxHeight,
                ...style
            }} 
            className={`lazy image ${isSvg ? 'svg' : ''} ${blur ? `blur-${blur}` : ''} ${loaded && 'loaded'} ${className}`}>
            <figure
                style={{
                    ...figureStyle
                }}
            >
                <picture className='image-body'>
                    <source srcSet={src} type='image' />
                    {!isSvg ?
                    <LazyLoadImage 
                        src={src}
                        srcSet={srcSet}
                        style={{
                            objectFit: objectFit && objectFit
                        }}
                        alt={alt}
                        onLoad={() => setTimeout(() => { setLoaded(true); }, 500)}
                    />
                    :
                    <>{src}</>
                }
                </picture>
            </figure>
        </div>
    );
}

export default LazyImage;