import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LazyImage } from '../../components/lazy';
import { ColorExtractor } from 'react-color-extractor';
import { ColorsContext } from '../../context/Colors';
import { getLuminance, hexToRgb } from '../../utils';
import { extractColors } from 'extract-colors';
import { CircularProgress } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../services';
import './Curso.scss';

export default function Curso() {
    const params = useParams();
    const { handleHeaderColor } = useContext(ColorsContext);
    const [ data, setData ] = useState(null);

    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        if (!loading) {
            return;
        }

        async function fetchCurso() {
            const cursoRef = doc(firestore, `aprende/${params.curso}`);
            const cursoSnap = await getDoc(cursoRef);

            if (cursoSnap.exists()) {
                const data = cursoSnap.data().data[0];

                setData(data);
                extractColors(data.cover)
                .then(res => {
                    handleHeaderColor(res[0].hex);
                })
                setLoading(false);
            } else {
                setData(undefined);
            }
        }

        fetchCurso();
    }, [loading])

    if (loading) {
        return (
            <div className='loading'>
                <CircularProgress className='circular-progress' />
            </div>
        )
    }

    return (
        <div className='curso'>
            <div className='curso-background'>
                <LazyImage 
                    objectFit='cover'
                    src={data.cover}
                />
            </div>
            <div className='curso-header'>
                <div className='logo'>
                    <LazyImage 
                        src={data.logo}
                    />
                </div>
                <div className="title">
                    <h1>{data.title}</h1>
                    <p>{data.description}</p>
                </div>
            </div>
            <iframe width="1903" height="750" src="https://www.youtube.com/embed/Zs_7dCgvtik" title="Probando comida mexicana en ALASKA | ¡Tacos en la nieve! ❄️🌮🥶" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    )
}
